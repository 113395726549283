@import "./styles/tailwind.scss";
@import "./styles/base.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.icon-font-family {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
}
.icon-font-family-large {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
}

.logoWidth {
  max-width: 120px;
}

@font-face {
  font-family: "Proxima-Nova";
  src: url("./assets/fonts/Proxima-Nova.otf");
}

@font-face {
  font-family: "Proxima-Nova-Medium";
  src: url("./assets/fonts/Proxima-Nova-Medium.otf");
}

@font-face {
  font-family: "Proxima-Nova-Semibold";
  src: url("./assets/fonts/Proxima-Nova-Semibold.otf");
}

@font-face {
  font-family: "Proxima-Soft-Bold";
  src: url("./assets/fonts/Proxima-Soft-Bold.otf");
}

@font-face {
  font-family: "Proxima-Soft-Medium";
  src: url("./assets/fonts/Proxima-Soft-Medium.otf");
}

@font-face {
  font-family: "Proxima-Soft-Regular";
  src: url("./assets/fonts/Proxima-Soft-Regular.otf");
}

@font-face {
  font-family: "Proxima-Soft-Semibold";
  src: url("./assets/fonts/Proxima-Soft-Semibold.otf");
}

:root,
#root,
#docs-root {
  --dark-blue: #004579;
  --required-red: #e00000;
  --app-grey: #767676;
  --app-blue: #0074bf;
  --app-black-shade-one: #2e2e2e;
}

@font-face {
  font-family: "Material Icons Outlined";

  font-style: normal;

  font-weight: 400;

  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v101/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUcel5euIg.woff2)
    format("woff2");
}

.material-icons-outlined {
  font-family: "Material Icons Outlined";

  font-weight: normal;

  font-style: normal;

  line-height: 1;

  letter-spacing: normal;

  text-transform: none;

  display: inline-block;

  white-space: nowrap;

  word-wrap: normal;

  direction: ltr;

  -webkit-font-feature-settings: "liga";

  -webkit-font-smoothing: antialiased;
}

.loader-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  z-index: 99;
  background: rgba(255, 255, 255, 0.5);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
