@import "../../index.scss";

.info-style {
    color: $darkergrey;
    font-size: 16px;
    text-align: center;
    font-family: "Proxima-Nova-Medium";
}

.label-style {
    color: $darkergrey;
    font-size: 16px;
}

.username-passowrd-tip {
    color: $darkergrey;
    font-size: 14px;
    font-family: "Proxima-Nova-Medium";
    text-align: left;
}


.input-style {
    border-width: 1px;
    border-color: $darkergrey;
    font-size: 18px;
    font-family: "Proxima-Nova-Medium";
    color: $tableDetails;
    text-align: center;
}

.container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .login-card {
    width: 50vw !important;
    height: 50vh !important;
  }
  .errorColor {
    color: red !important;
  }
  .errorHightlight {
    border-color: red !important;
  }
  .error {
    color: red !important;
    float: left;
    padding-left: 1px;
  }
  .userMargin {
    margin-left: -15px;
  }

  .checkmark-validation-error{
    border: 2px solid red !important;
  }

  input[type="radio"].toggle {
    display: none;
    & + label{
        cursor: pointer;
        min-width: 60px;
        max-height: 40px;
        &:hover{
            background: none;
            color: $blue;
        }
        &:after{
            background: $blue;
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
            width: 100%;
            z-index: -1;
        }
        &:disabled {
            background-color: $darkergrey;
        }
    }
    &.toggle-left + label {
        border-right: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        color: $blue;
        &:after{
            left: 100%
        }
        &:disabled {
            background-color: $darkergrey;
        }
    }
    &.toggle-right + label{
        color: $blue;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        &:after{
            left: -100%;
        }
        &:disabled {
            background-color: $darkergrey;
        }
    }
    &:checked + label {
        cursor: default;
        color: #fff;
        background-color: $blue;
        transition: color 200ms;
        &:after{
            left: 0;
        }
        &:disabled {
            background-color: $darkergrey;
        }
    }
  }
  
  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
  
    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: $white;
        border: 2px solid $blue;
        border-radius: 5px;
    }
  
    /* On mouse-over, add a grey background color */
    :hover input ~ .checkmark {
        background-color: $white;
    }
  
    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
        background-color: $blue;
    }
  
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
  
    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }
  
    /* Style the checkmark/indicator */
    .checkmark:after {
        left: 6px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
  }
  
  /* Customize the label (the container) */
  .radio-container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 5px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* Hide the browser's default radio button */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
  
    /* Create a custom radio button */
    .radiomark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: $white;
        border-radius: 50%;
        border: 2px solid $blue;
  
    }
  
    /* On mouse-over, add a grey background color */
    :hover input ~ .radiomark {
        background-color: $white;
    }
  
    /* When the radio button is checked, add a blue background */
    input:checked ~ .radiomark {
        background-color: $white;
    }
  
  
  
    /* Create the indicator (the dot/circle - hidden when not checked) */
    .radiomark:after {
        content: "";
        position: absolute;
        display: none;
    }
  
    /* Show the indicator (dot/circle) when checked */
    input:checked ~ .radiomark:after {
        display: block;
    }
  
    /* Style the indicator (dot/circle) */
    .radiomark:after {
        top: 4px;
        left: 4px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $blue;
    }
  }