.font-size {
    &-xxxl {
        font-size: 2rem !important;
    }
    &-xxl {
        font-size: 1.75rem !important;
    }
    &-xl {
        font-size: 1.45rem !important;
    }
    &-l {
        font-size: 1.25rem !important;
    }
    &-m {
        font-size: 1rem !important;
    }
    &-s {
        font-size: 0.75rem !important;
    }
    &-xs {
        font-size: 0.5rem !important;
    }
}