@import "../../index.scss";

.info-style {
    color: $darkergrey;
    font-size: 16px;
    text-align: center;
    font-family: "Proxima-Nova-Medium";
}

.label-style {
    color: $darkergrey;
    font-size: 16px;
}

.username-passowrd-tip {
    color: $darkergrey;
    font-size: 14px;
    font-family: "Proxima-Nova-Medium";
    text-align: left;
}

.input-style {
    border-width: 1px;
    border-color: $darkergrey;
    font-size: 18px;
    font-family: "Proxima-Nova-Medium";
    color: $tableDetails;
    text-align: center;
}

.container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .login-card {
    width: 50vw !important;
    height: 50vh !important;
  }
  .errorColor {
    color: red !important;
  }
  .errorHightlight {
    border-color: red !important;
  }
  .error {
    color: red !important;
    float: left;
    padding-left: 1px;
  }
  .userMargin {
    margin-left: -15px;
  }