$blue: #0074BF;
$black: #000000;
$darkgrey: #505050;
$grey: #767676;
$white: #FFFFFF;
$red: #E00000;
$darkergrey: #58595B;
$tableDetails: #2E2E2E;
$table-title-blue: #004579;
$modal-header-color: $blue;
$modal-body-color: $darkgrey;
$modal-positive-btn-color: $white;
$modal-negative-btn-color: $blue;



