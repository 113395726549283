@import "../../index.scss";

.popup-header {
  padding: 1rem 1.5rem 0;
}

.popup-middle-content {
  padding: 0.25rem 1.5rem 0.5rem;
  text-align: left;
  color: $darkergrey;
}

a:active {
  color: $blue !important;
}

.popup-accept-button {
  padding-top: 2px;
  margin-left: 10px !important;
}

.popup-decline-button {
  padding-top: 2px;
  border-radius: 0.375rem;
  border: 1px solid #0074bf;
}

.input-style {
  border-width: 1px;
  border-color: $darkergrey;
  font-size: 18px;
  font-family: "Proxima-Nova-Medium";
  color: $tableDetails;
  text-align: center;
}

.errorColor {
  color: red !important;
}
.errorHightlight {
  border-color: red !important;
}
.error {
  color: red !important;
  float: left;
  padding-left: 1px;
}
.userMargin {
  margin-left: -15px;
}
